import moment from 'moment';
import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '@/shared/utils';

const defaultEmptyTime = '0001-01-01T00:00:00Z';

export const addressOptions = {
	fromAddress: {
		code: 'fromAddress',
		label: 'Address',
		queryName: 'address',
		transportField: 'fromAddressId',
		objectGqlKey: 'id'
	},
	fromLocation: {
		code: 'fromLocation',
		label: 'Location',
		queryName: 'location',
		transportField: 'fromLocationId',
		objectGqlKey: 'addressId5'
	},
	fromUser: {
		code: 'fromUser',
		label: 'User',
		queryName: 'user',
		transportField: 'fromUserId',
		objectGqlKey: 'addressID'
	},
	toAddress: {
		code: 'toAddress',
		label: 'Address',
		queryName: 'address',
		transportField: 'toAddressId',
		objectGqlKey: 'id'
	},
	toLocation: {
		code: 'toLocation',
		label: 'Location',
		queryName: 'location',
		transportField: 'toLocationId',
		objectGqlKey: 'addressId5'
	},
	toUser: {
		code: 'toUser',
		label: 'User',
		queryName: 'user',
		transportField: 'toUserId',
		objectGqlKey: 'addressID'
	}
};

export const possibleAddressKeys = {
	from: ['fromAddress', 'fromLocation', 'fromUser'],
	to: ['toAddress', 'toLocation', 'toUser']
};

export const transportTypeAddressIds = {
	from: 'fromAddressId',
	to: 'toAddressId'
};

export const transportsQueries = {
	async location() {
		try {
			const { data } = await apollo.query({
				query: gql`
					query {
						GetProjectLocationList {
							id
							setName
							addressId5
						}
					}
				`,
				fetchPolicy: 'network-only'
			});
			return data.GetProjectLocationList;
		} catch (e) {
			console.log({ e });
		}
	},
	async user() {
		try {
			const { data } = await apollo.query({
				query: gql`
					query {
						GetUsersOfProject {
							id
							name
							firstName
							addressID
						}
					}
				`,
				fetchPolicy: 'network-only'
			});
			return data.GetUsersOfProject;
		} catch (e) {
			console.log({ e });
		}
	}
};

/**
 * @param {Object} key
 * @return {Object}
 */
export const quickSave = async (transportId, payload) => {
	const keys = Object.keys(payload);
	const { data } = await apollo.mutate({
		mutation: gql`
          mutation ($TransportId: ID!, $UpdatedTransport: TransportInput!) {
            AddUpdTransport(
              TransportId: $TransportId
              UpdatedTransport: $UpdatedTransport
            ) {
              id
              ${keys.join('\n')}
            }
          }
        `,
		variables: {
			TransportId: parseInt(transportId),
			UpdatedTransport: payload
		}
	});
	return data.AddUpdTransport;
};

/**
 * @param {String} start
 * @param {String} end
 * @return {String}
 */
export const getDiffInMins = (start, end) => {
	const _s = moment(new Date(start), 'DD-MM-YYYY HH:mm');
	const _e = moment(new Date(end), 'DD-MM-YYYY HH:mm');
	return _e.diff(_s, 'minutes');
};

/**
 * @param {String|Date} d1
 * @param {String|Date} d2
 * @return {String}
 */
export const getLateSignIndicator = (d1, d2) => {
	return new Date(d1) < new Date(d2) ? '+ ' : '- ';
};

/**
 * @param {Array|null} meansMap
 * @param {Number|String|null} meanId
 * @return {String}
 */
export const getTravelModeFromTransportMean = (meanId = 0) => {
	// return { travelMode: 'DRIVING' }
	const map = {
		// DRIVING: [0, 2],
		// TAXI: [10],
		// TWO_WHEELER: [3],
		// TRANSIT: [7],
		// TRAIN: [4],
		// CYCLING: [9]
		// 4: {
		// 	travelMode: 'TRANSIT',
		// 	transitOptions: {
		// 		modes: ['TRAIN'],
		// 		routingPreference: 'FEWER_TRANSFERS'
		// 	}
		// },
		// 14: {
		// 	travelMode: 'TRANSIT',
		// 	transitOptions: {
		// 		modes: ['TRAIN'],
		// 		routingPreference: 'FEWER_TRANSFERS'
		// 	}
		// },
		// 7: { travelMode: 'TRANSIT' },
		1: { travelMode: 'DRIVING' },
		2: { travelMode: 'DRIVING' },
		3: { travelMode: 'DRIVING' },
		4: { travelMode: 'DRIVING' },
		6: { travelMode: 'TWO_WHEELER' },
		11: { travelMode: 'DRIVING' },
		12: { travelMode: 'DRIVING' },
		14: { travelMode: 'DRIVING' },
		15: { travelMode: 'DRIVING' },
		16: { travelMode: 'TWO_WHEELER' },
		17: { travelMode: 'TWO_WHEELER' },
		44: { travelMode: 'BICYCLING' },
		45: { travelMode: 'BICYCLING' }
		// 10: { travelMode: 'DRIVING' },
		// 11: { travelMode: 'DRIVING' },
		// 12: { travelMode: 'DRIVING' },
		// 10: { travelMode: 'TAXI' },
		// 9: { travelMode: 'BICYCLING' },
		// 15: { travelMode: 'TWO_WHEELER' },
		// 16: { travelMode: 'TWO_WHEELER' }
	};

	const res = map[parseInt(meanId)];
	return isNil(res) ? { travelMode: 'DRIVING' } : res;
	// console.log({ meansMap })
	// return { travelMode: 'DRIVING' }
};

/**
 * @param {String} dateTime
 * @param {Number} val
 * @param {Object} options
 * @return {Date}
 */
export const setDurationToDate = (dateTime, val, options = { unit: 'seconds', type: 'add' }) => {
	if (!['remove', 'add'].includes(options.type)) {
		throw new Error("You only can use 'add' or 'remove' type");
	}

	const _d = new Date(dateTime);
	const actionField = options.type === 'remove' ? 'subtract' : 'add';

	return moment(_d)[actionField](val, options.unit).format();
};

/**
 * @param {Object} transport
 * @return {Boolean}
 */
export const isArrived = (transport) => {
	if (isNil(transport)) return false;
	return defaultEmptyTime !== transport.effArrivalTime;
};

/**
 * @param {Object} transport
 * @return {Boolean}
 */
export const isStarted = (transport) => {
	if (isNil(transport)) return false;
	const { effDepartureTime, effArrivalTime } = transport;
	return defaultEmptyTime !== effDepartureTime && effArrivalTime === defaultEmptyTime;
};
